@import '../../../Sass/Mixins.scss';
@import '../../../Sass/Variables.scss';

.moreOption {
    // background-image: url('../../../assets/Icon/moreOption_background.svg');
    @include dimensions(auto, 179px);
    // right: 570px;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: inherit;
    background-size: cover;
    // bottom: 70px;
    position: absolute;
    // padding-bottom: 10px;
    border: 1px solid #3D3D3D;
    border-radius: 6px;
    // overflow: hidden;
    background: #222222;
    // &_background {

    // }
    &::after {
        content: '';
        @include dimensions(15px,15px);
        content: '';
        position: absolute;
        right: 13px;
        bottom: -8px;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid #3d3d3d;
        border-bottom: 1px solid #3d3d3d;
        transform: rotate(45deg);
        background: linear-gradient(to bottom right, $secondary-color1 0%, $secondary-color1 50%, $secondary-color1 50%, $secondary-color1 100%);
    }
    &_inner {
        // border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 1px;
        // justify-content: center;
        background: $secondary-color1;
        margin: 0;
        border-radius: 6px;
        overflow: hidden;
        @include dimensions(calc(100% - 0px), calc(100% - 0px));
        &_row {
            @include dimensions(39px, 100%);
            // flex-grow: 1;
            // background: #222222;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            &:hover {
                opacity: 0.8;
            }
            &::after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: #3D3D3D;
                position: absolute;
                bottom: -1px
            }
            &_svg {
                @include dimensions(auto, 47px);
                display: flex;
                align-items: center;
                justify-content: center;
                &.start {
                    svg {
                        path {
                            stroke: var(--secondary-text-color);
                            fill: none;
                        }
                    }
                }
                svg {
                    @include dimensions(22px, 22px);
                    path {
                        fill: var(--secondary-text-color);
                    }
                    .svg_circel, line {
                        stroke: var(--secondary-text-color);
                    }
                }
            }
            &_text {
                font-family: 'Cabin';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: $secondary-text-color;
            }
        }
    }
}
.background {
    background-color: $background;
}
.Casting {
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: unset;
    height: 100vh;
    width: 100%;
    .boardContainers {
        max-height: 379px;
        @include dimensions(auto, 303px);
    }
    .fullScreenImg {
        position: absolute;
        bottom: 30px;
        right: 30px;
        height: 36px;
        width: 36px;
        cursor: pointer;
    }
    // .statsBox_container.showHearteRate {
    //     width: 674px;
    // }
    .statsBox {
        padding: 0 20px 0;
        .calories {
            border: 1.2px solid #CF832D;
            // backdrop-filter: blur(19px);
            width: 101px;
            height: 56px;
            border-radius: 8px;
            font-weight: 500;
            font-size: 26px;
            line-height: 32px;
            justify-content: center;
            .calories_svg {
                width: 24px;
                height: 24px;
            }
        }
        .stats_heartrate_ongoing {
            height: 56px;
            width: 147px;
            background: transparent;
            .stats_heart {
                height: 24px;
                width: 27px;
            }
            .stats_number {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.02em;
                span {
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 32px;
                    color: unset;
                }
            }
        }

    }
}
.coachVideo {
    width: 100%;
    object-fit: cover;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
}