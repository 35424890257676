@import '../../../Sass/Variables.scss';
.backgroundC {
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #00000099;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .percent{
        color: $primary-color;
        font-size: 32px;
        font-weight: 600
    }
    .percent_text {
        color: $primary-color;
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
    }
    
    .loader {
        // width: 100%;
        // height: 100%;
        // background-color: #0000005c;
        // z-index: 9999;
        // position: fixed;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        
        // svg {
        //     position:fixed;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        // }
    }
    
}    

.backgroundC.smallLoader {
    position: absolute !important;
    z-index: 122 !important;
    svg {
        position: inherit !important;
    }
}