@use "sass:math";
@import '../../../Sass/Mixins.scss';
@import '../../../Sass/Variables.scss';

input[type=range][orient=vertical]
{
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* Chromium */
    // width: 8px;
    // height: 175px;
    // padding: 0 5px;
}

.slider{
  -webkit-appearance: none;
  background: linear-gradient(90deg, $primary-color1 100%, #767676 100%);
  border-radius: 5px;
}

.slider::-webkit-slider-runnable-track {
  width: 8px;
  height: 121px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $primary-color1;
  margin-left: -6px;
  cursor: pointer;
}

.slider:focus {
  outline: none;
}

@media screen and (max-width: 1500px) {
  .slider::-webkit-slider-runnable-track {
    height: 6px;
  }
  .slider::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    margin-top: -5px;
  }
}



// ------------------------

$slider-track-thickness: 10px;
$slider-thumb-size: 18px;
$slider-height: 116px;
$slider-width: 56px;
$color-theme: #3D3D4A;
$color-track: #767676;

@mixin track {
  border: none;    
  background: $color-track;
  width: $slider-track-thickness;
  border-color: $color-track;
  border-radius: 10px;
  // box-shadow: 0 0 0 2px $color-theme;
}

@mixin thumb {
  // This seemed buggy to make it work pretty for all modern browsers.
  // A dummy visual UI thumb has been added. 
  width: $slider-thumb-size; 
  height: $slider-thumb-size;  
  opacity: 0;
}

*, *:before,*:after{
  box-sizing: border-box;
}

.range-slider {    
  display: inline-block;
  width: $slider-width;
  position: relative;
  opacity: 1;  
  text-align: center;  
  height: $slider-height;
  max-height: 100%;
  cursor: pointer;
  //box-shadow: 0 0 0 1px deeppink;  // debug
  // &:before{
  //   // Display value
  //   position: absolute;
  //   top: -2em;    
  //   left: .5em;
  //   content: attr(data-slider-value) '%';
  //   color: white;        
  //   font-size: 90%;
  // }
  &__thumb{
    &[data-slider-value~="0"] {
      background: $secondary-text-color;
    }
    position: absolute;
    left: math.div($slider-width,2) - math.div($slider-thumb-size,2);
    width: $slider-thumb-size;
    height: $slider-thumb-size;
    line-height: $slider-thumb-size;
    background: $primary-color1;
    color: #777;
    font-size: 50%;
    // box-shadow: 0 0 0 4px $color-theme;
    border-radius: 50%;
    pointer-events: none;    
    cursor: pointer;
    content: attr(data-slider-value) '%';
    // @include backgroundBar($value:attr(data-slider-value));
  }
  &__bar{
    cursor: pointer;
    left: math.div($slider-width, 2) - math.div($slider-track-thickness, 2);
    bottom: 0;
    position: absolute;
    background: linear-gradient($primary-color1,$primary-color1);
    pointer-events: none;
    width: $slider-track-thickness;    
    border-radius: 10px;
  }
  input[type=range][orient=vertical]
  {
    //outline: 1px dashed white; // debug
    position: relative;  
    margin: 0;
    height: 100%;  
    width: 100%;
    display: inline-block;  
    position: relative;    
    writing-mode: bt-lr; // IE
    -webkit-appearance: slider-vertical;  // webkit      
    &::-webkit-slider-runnable-track, 
    &::-webkit-slider-thumb {
      -webkit-appearance: none;   
    }

    &::-webkit-slider-runnable-track {
      @include track;
    }
    &::-moz-range-track {
      @include track;
    }
    &::-ms-track {
      @include track;
      color: transparent;
      height: 100%;  
    }
    &::-ms-fill-lower, 
    &::-ms-fill-upper, 
    &::-ms-tooltip {
      display: none;
    }

    &::-webkit-slider-thumb {    
      @include thumb;
    }
    &::-moz-range-thumb {
      @include thumb;
    }
    &::-ms-thumb {
      @include thumb;
    }   
  }
}