@import '../../../Sass/Variables.scss';
@import '../../../Sass/Mixins.scss';
.stopCoachVideo {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-text-color;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 307px;
    height: 72px;
}
.rangeSlider_div:first-child, .rangeSlider_div:last-child {
    margin: 0px;
}
.mediaAudio {
    opacity: 0;
    position: absolute;
    // top: 300px;
    // z-index: 1111111;
}
.hide {
    opacity: 0;
    position: absolute;
}
.swapIcon {
    height: 36px;
    width: 36px;
    background: var(--box-border-color);
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    svg {
        path {
            fill: var(--secondary-text-color);
        }
    }
}
.pictureInPicture {
    position: absolute;
    width: 218px;
    height: 122px;
    bottom: 30px;
    right: 96px;
    background: linear-gradient(270deg, #000000 -2.6%, rgba(0, 0, 0, 0) 34.77%);
    border: 1px solid #767676;
    border-radius: 6px;
    overflow: hidden;
    z-index: 23;
    .coach_div {
        height: 80px;
        width: 80px;
    }
    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.full {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -1;
    // background-color: var(--background);
    video {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.infoBox1 {
    padding: 15px 20px 15px 20px;
    // margin-top: 5px;
    @include flex(row, nowrap, space-between, center);
    z-index: 5;
    gap: 60px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) -5.05%, rgba(0, 0, 0, 0) 91.11%);
    position: fixed;
    width: 100%;
    .logoBox {
        position: initial;
    }
    .rangeSlider {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px;
        // padding: 0px 65px;
        flex-grow: 1;
        width: unset;
        gap: 6px;
        
        .rangeSlider_div.active {
            svg.segment_icon {
                .dynamic {
                    fill: $primary-color1;
                }
            }
            svg.segment_icon.new {
                path, ellipse {
                    fill: $primary-color1;
                    stroke: $primary-color1;
                }
            }
        }
        .rangeSlider_div {
            position: inherit;
            margin: 0 0px;
            svg.segment_icon {
                position: absolute;
                width: 28px;
                height: 28px;
                top: -30px;
                left: 0px;
                .dynamic {
                    fill: #868686;
                }
            }
            svg.segment_icon.new {
                path, ellipse {
                    fill: $progressBar-color;
                    stroke: $progressBar-color;
                    // stroke-width: 0px;
                }
            }

        }

        .hiddenSeekbar {
            position: absolute;    
            z-index: 99999;
            margin-left: 111px;
            width: calc(100% - 222px);
        }
        .segmentTime {
            position: initial;
            // text-shadow: 1px 1px 4px #000000;
            font-family: 'Cabin';
            font-size: 22px;
            min-width: 105px;
            font-weight: bold;
            color: #fff;

        }
        .activityTime {
            position: relative;
            right: unset;
            // text-shadow: 1px 1px 4px #000000;
            font-family: 'Cabin';
            font-size: 22px;
            min-width: 105px;
            font-weight: bold;
            color: #fff;
            text-align: right;
        }
        .activityTime1 {
            // position: relative;
            // right: unset;
            // text-shadow: 1px 1px 4px #000000b3;
            // font-family: 'Cabin';
            // font-size: 22px;
            // min-width: 95px;
            // font-weight: bold;
            // color: #fff;

            // border-radius: 4px;
            // border: 1px solid #767676;
            // backdrop-filter: blur(3px);
            // background: rgba(0, 0, 0, 0.4);
            display: flex;
            align-items: center;
            font-family: Cabin;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            text-transform: capitalize;
            justify-content: center;
            // text-shadow: none;
            color: #fff;
            height: 36px;
            right: unset;
            min-width: 83px;
            position: relative;
            padding: 4px 14px;
            width: max-content;
        }
        .segmentName {
            // text-shadow: 1px 1px 4px #000000;
            font-family: 'Cabin';
            text-align: center;
            font-size: 24px;
            top: 24px;
            left: unset;
            font-weight: bold;
            color: #fff;
            margin: 0px;
            right: -40px;
            // max-width: 170px;
            width: unset;
            white-space: nowrap;
            position: absolute;
            overflow: unset;
            text-overflow: unset;
        }
    }
    .time {
        width: 75px;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 75px;
        // border: 4px solid #424242;
        // border-radius: 57px;
        position: relative;
            .innerCircle {
                height: 60px;
                width: 60px;
                border: 2px solid #868686;
                border-radius: 42px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
            .innerCircle > p {
                font-family: 'Cabin';
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                width: 52px;
                margin: 0;
            }
            .newInnerCircle > p {
                font-family: 'Cabin';
                font-size: 15px;
                font-weight: bold;
                color: #fff;
                width: 56px;
                margin: 0;
            }
        P {
            // text-shadow: 1px 1px 4px #000000b3;
            // text-shadow: 1px 2px #000000;
            font-family: 'Cabin';
            font-size: 32px;
            font-weight: bold;
            color: $_white;        
        }
    }
}
.videoControls {
    position: absolute;
    width: 400px;
    left: 50%;
    top: 50%;
    border-radius: 43px;
    z-index: 999;
    transform: translate(-50%,-50%);
    @include flex(row, nowrap, space-between, center);
    .backwardSec {
        position: absolute;
        color: #FFFFFF;
        z-index: -1;
        left: 23px;
        @include font(20px, 500);
    }
    .forwardSec {
        position: absolute;
        color: #FFFFFF;
        z-index: -1;
        right: 25px;
        @include font(20px, 500);
    }
    & > img {
        height: 70px;
        width: 70px;
        cursor: pointer;
    }
}
@media screen and (max-width: 1500px) {
    .infoBox {
        .rangeSlider {
            width: calc(100% - 395px);
        }
    }    
}
@media screen and (min-width: 1024px)and (max-width: 1223px) {  
    .videoControls {
        width: 300px;
        & > img {
            height: 50px;
            width: 50px;
        }
    }
}

@media screen and (min-width: 1224px)and (max-width: 1500px) { 
    .videoControls {
        width: 350px;
        & > img {
            height: 60px;
            width: 60px;
        }
    }
}
