@import '../../../Sass/Variables.scss';

.toaster_div {
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
    z-index: 99999999;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px;
    right: 0;

    .Toaster {
        display: flex;
        padding: 10px;
        border-radius: 24px;
        border: 1px solid #FFFFFF;
        background-color: $primary-color1;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        margin-top: 10px;
        min-width: 250px;
        
        svg {
            color: #ffffff;
            font-size: 36px;
            margin-right: 10px;
            margin-left: 10px;
        }
    
        p {
            font-size: 15px;
            font-weight: 500;
             flex-grow: 1;
            color: #FFFFFF;    
        }
    }
}


 