.local {
    padding: 50px;
    .login {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            margin: 5px;
        }
    }
    .staticText {
        display: flex;
        div {
            width: 50%;
            color: #ffffff;
        }
    }
}