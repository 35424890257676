@import './Variables.scss';

@mixin flex( $direction, $wrap, $content, $item ) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $content;
    align-items: $item;
}

@mixin font($size, $weight) {
    font : {
        family: $Font_avenir;
        size: $size;
        weight: $weight;
    }
}

@mixin border($size, $style, $color) {
    border : {
        width: $size;
        style: $style;
        color: $color;
    }
}

@mixin newBorder($radius:5px,$border:#272727) {
    border: 1px solid $border;
    box-shadow: 0px 2px 4px #00000080;
    border-radius: $radius;
}

@mixin dimensions( $height, $width ) {
    height: $height;    
    width : $width;
}

@mixin backgroundImage ($URL) {
    min-width: 100%;
    min-height: 100vh;
    background-image: url($URL);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

// New Code
@mixin absolute( $top, $right, $bottom, $left ) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    margin: auto;
}