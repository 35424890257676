@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;600&display=swap');

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
}

:root {
  --theme-color: 'dark'; // =====MAIN THEME COLOR   =====
  --primary-color: #F22B5D; // =====MAIN THEME COLOR   =====
  --chat-count-background-color: #F22B5D; // =====MAIN THEME COLOR   =====
  --progressBar-color: #FFFFFF; // =====progess bar COLOR   =====
  --secondary-color: #FFFFFF; // =====MAIN THEME COLOR   =====
  --chat-text-color: #FFFFFF; // =====MAIN THEME COLOR   =====
  --chat-background-color: #FFFFFF;
  --moreSection-color: #000000; // =====MAIN THEME COLOR   =====
  --tertiory-color: #FFFFFF; // =====MAIN THEME COLOR   =====
  --primary-text-color: #FFFFFF;
  --secondary-text-color: #0C0C0C;
  --box-border-color: #0C0C0C;
  --layer-one: #0C0C0C;
  --background-color: #0C0C0C; // =====USED IN STATS BOXES=====
  --dynamic-variable: #000000; // =====USED IN STATS BOXES=====
  --background: #222222; // =====USED IN STATS BOXES=====
  --sender-background: #000000;
  --receiver-background: #000000;
  --personal-background: #000000;
  --sender-background-text: #ffffff;
  --receiver-background-text: #ffffff;
  --personal-background-text: #ffffff;
}


*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: rgba(255, 255, 255, 0);
}

body {
  margin: 0;
  padding: 0;
  // background-color: #222222;
  background-color: var(--background);
  font-family: cabin;
}

input {
  font-family: cabin !important;
  outline: none !important;
}

select {
  outline: none !important;
}

textarea {
  resize: none;
  outline: none !important;
}

button {
  outline: none !important;
  border: none !important;
}

label {
  margin-bottom: 0 !important;
}

/* Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}