@import '../../../sass/Variables.scss';
@import '../../../sass/Mixins.scss';

.newCasting {
    position: relative;
    @include flex(column, nowrap, space-between, unset);    
    @include dimensions( 100vh, 100%);
    // background-color: #222222;

    // .coachVideo {
    //     width: 100%;
    //     object-fit: contain !important;
    //     height: 100%;
    //     position: relative;
    //     z-index: auto;
    // }

    .waiting_header {
        padding: 15px 20px 15px 20px;
        // margin-top: 5px;
        @include flex(row, nowrap, space-between, center);
        z-index: 5;
        gap: 60px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) -5.05%, rgba(0, 0, 0, 0) 91.11%);
        position: fixed;
        width: 100%;
        .logoBox, .stopWatch {
            position: initial;
            margin: initial;
        }
    }

    .coachVideoWrZoom {
        width: 100%;
        object-fit: cover !important;
        height: 100%;
        position: relative;
        z-index: auto;
    }
    .list_button_container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // margin-top: 5px;
    }
    .coachDiv {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

// .background_popup {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     z-index: 9999999;
//     @include flex(column, nowrap, center, center);  
//     background-color: rgba(0, 0, 0, 0.7);  
//     .popup {
//         background: #222222;
//         border: 1.5px solid #767676;
//         border-radius: 12px;
//         padding: 30px;
//         @include flex(column, nowrap, space-between, center);    
//         .text {
//             font-weight: 400;
//             font-size: 20px;
//             line-height: 24px;
//             text-align: center;
//             letter-spacing: 0.02em;
//             color: #FFFFFF;
//         }
//         .button {
//             margin-top: 30px;
//             background: $primary-color;
//             border-radius: 8px;
//             width:93px;
//             height:34px;
//         }
//     }
// }