@import '../../../Sass/Variables.scss';

.valueCheck {
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow:auto;
  // font-size: 14px;
}

.tooltips {
    position: relative;
    display: inline-flex;
    word-break: break-word;
  }
  
  .tooltip-trigger.user-name {
    max-width: 97%;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tooltip-trigger {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .tooltip-bubble {
    // min-width: 150px;
    max-width: 210px;
    position: absolute;
    z-index: 10;
    &::after {
      content: '';
      position: absolute;
    }
  }
  
  .tooltip-top {
    bottom: 100%;
    left: 50%;
    padding-bottom: 9px;
    transform: translateX(-50%);
    
    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid $tooltip-color;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  .tooltip-bottom {
    top: 100%;
    left: 50%;
    padding-top: 9px;
    transform: translateX(-50%);
    
    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid $tooltip-color;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  .tooltip-left {
    top: 50%;
    right: 100%;
    padding-right: 9px;
    transform: translateY(-50%);
    
    &::after {
      border-left: 9px solid $tooltip-color;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  
  .tooltip-right {
    top: 50%;
    left: 100%;
    padding-left: 9px;
    transform: translateY(-50%);
    
    &::after {
      border-right: 9px solid $tooltip-color;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  
  .tooltip-message {
    background: $tooltip-color;
    border-radius: 3px;
    color: $tooltip-text-color;
    font-size: 14px;
    line-height: 1.4;
    padding: .75em;
    text-align: center;
    word-break: break-word;
    // max-height: 100px;
    // overflow: scroll;

    ul {
      text-align: left;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-inline-start: 20px;
    }

    .tooltip_message_span {
      margin-right: 5px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }
  }



  // --------------------------------------------------------------------------------------------------------------------------------------------
  .tooltips-bubble {
    min-width: 150px;
    max-width: 210px;
    position: absolute;
    z-index: 9999;
    &::after {
      content: '';
      position: absolute;
    }
  }

  .tooltips-right {
    padding-left: 9px;
    transform: translateY(-50%);
    
    &::after {
      border-right: 9px solid $tooltip-color;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .tooltips-top {
    padding-bottom: 9px;
    transform: translateX(-50%);
    
    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid $tooltip-color;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .tooltips-bottom {
    padding-top: 9px;
    transform: translateX(-50%);
    
    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid $tooltip-color;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  .tooltips-left {
    padding-right: 9px;
    transform: translateY(-50%);
    
    &::after {
      border-left: 9px solid $tooltip-color;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }



