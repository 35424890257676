$Font_avenir: Avenir;
$_pink: #F22B5D;
$_white: #FFFFFF;
$_black: #0C0C0C;

$tooltip-color: #424242;
$tooltip-text-color: #ffffff;

$background-color: #0C0C0C;
$white-color: #ffffff;
$progressBar-color: #ffffff;
$black-color: #000000;
$primary-color: #F22B5D;
$third-color: #1f1f1f;

$width: 248px;


// ============WHITE LABELLING CSS VARIABLES===========
$theme       : var(--theme-color);
$primary-color1       : var(--primary-color);
$progressBar-color       : var(--progressBar-color);
$secondary-color1     : var(--secondary-color);
$chat-text-color     : var(--chat-text-color);
$chat-count-background-color     : var(--chat-count-background-color);
$tertiory-color: var(--tertiory-color);
$primary-text-color   : var(--primary-text-color);
$secondary-text-color : var(--secondary-text-color);
$chat-background-color : var(--chat-background-color);
$box-border-color: var(--box-border-color);
$layer-one: var(--layer-one);
$background-color1    : var(--background-color);
$background    : var(--background);

$formInput-height: 57px;
$formInput-border: #434343;
$formInput-background: #121212;
$formInput-checkbox-background: #121212;
$sender-background: var(--sender-background);
$receiver-background: var(--receiver-background);
$personal-background: var(--personal-background);
$sender-background-text: var(--sender-background-text);
$receiver-background-text: var(--receiver-background-text);
$personal-background-text: var(--personal-background-text);



:export {
    theme : $theme;
    primaryColor1 : $primary-color1;
    progressBarColor : $progressBar-color;
    secondaryColor1 : $secondary-color1;
    chatTextColor : $chat-text-color;
    chatCountBackgroundColor : $chat-count-background-color;
    tertioryColor : $tertiory-color;
    primaryTextColor : $primary-text-color;
    secondaryTextColor : $secondary-text-color;
    chatBackgroudColor : $chat-background-color;
    boxBorderColor : $box-border-color;
    layerOne : $layer-one;
    background-color1 : $background-color1;
    background : $background;
    senderBackground:$sender-background;
    receiverBackground:$receiver-background;
    personalBackground:$personal-background;
    senderBackgroundText:$sender-background-text;
    receiverBackgroundText:$receiver-background-text;
    personalBackgroundText:$personal-background-text;
}


$extraLow:  #ffffff;
$veryLow:  #507FB1;
$low:  #55BA6B;
$medium:  #D6C345;
$high:  #CF832D;
$veryHigh:  #E52353;




