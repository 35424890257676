@import '../../../Sass/Variables.scss';
@import '../../../Sass/Mixins.scss';

.btns {
    text-align: center;
    border-radius: 8px;
    letter-spacing: 0;
    cursor: pointer;    
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $primary-text-color;
}

.btns-primary {
    background: $primary-color1;
    width:93px;
    height:34px;
}

.activeButton {
    border-radius: 6px;
    cursor: pointer;
    color: $white-color;
    font-size: 16px;
    padding: 10px 20px;
    font-weight: 300;
    text-align: center;
    background-color: $primary-color;
    text-transform: capitalize;
    min-width: 120px;
    border: 1px solid $primary-color !important;

    svg {
        margin-right: 5px;
    }
    &:hover {
        opacity: 0.7;
    }
}