@import '../../../Sass/Variables.scss';
@import '../../../Sass/Mixins.scss';
.Backdrop.zIndex {
    z-index: 9999;
}
.Backdrop {
    width: 100%;    
    height: 100%;    
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: #000000ad;
    // overflow: scroll;

    .historyModals {
        height: 90vh;
        margin: 5vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // overflow: scroll;

        .modal_div {
            max-height: 100%;
            position: relative;
            display: flex;
            // background: #000000;
            // border: 1px solid #ffffff;
            border-radius: 10px;
            max-width: 400px;

            .oval {
                position: absolute;
                box-sizing: border-box;
                height: 42.2px;
                cursor: pointer;
                width: 42.23px;
                right: -20px;
                top: -20px;
                border-radius: 22px;
                border: 1px solid #FFFFFF;
                background-color: #F22B5D;
                z-index: 1;

                svg {
                    color: #FFFFFF;    
                    margin-left: 11px;
                    margin-top: 7px;
                    font-size: 25px;
                }
            }    
        }
    }
}
.displayF{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-body {
        height: 100%;
        overflow: scroll;
    }
}

.reason {
    @include newBorder();
    background-color: $background-color;
    color: $white-color;
    
    .modal-header {
        .modal-header-title {
            color: $primary-color !important;
        }
    }
}

.whiteB {
    background-color: $white-color;
    box-shadow: 0px 0px 0px 2px $primary-color;
}

.blackB {
    background-color: $third-color;
    color: $white-color;
    box-shadow: 0px 0px 0px 1px $white-color;
}

.blackBack {
    background-color: #000000;
    
    color: $white-color;
    box-shadow: 0px 0px 0px 1px $white-color;
}

.blackC {
    background-color: $third-color;
    color: $white-color;
    box-shadow: 0px 0px 0px 1px $white-color;
    overflow: inherit !important;
}

.video_modal {
    width: 700px;
    box-sizing: border-box;
    overflow: auto;

    .videoTag {
        opacity: 1;
        border: 0px;
        border-radius: 10px;
        overflow: hidden;

        video:focus {
            outline: 0px !important;
        }
        video {
            border: 0px !important;
            border-radius: 10px;
        }
    }
}

.modal-popUp {
    min-width: 450px;
    box-sizing: border-box;
    position: relative;
    overflow: auto;
    opacity: 0.9;
    border-radius: 4px;
    
    .modal-header{
        padding: 0px 20px;
        background-color: $background-color;
        float: left;
        width: 100%;
        height: 70px;
        // border-bottom: 1px solid #383838;

        .modal-header-title {
            color: #ffffff;
            font-size: 25px;
            font-weight: 600;
            line-height: 70px;
        }

        .modal-header-cross {
            position: absolute;
            top: 3px;
            right: 8px;
            color: #ff256a;
            border-radius: 3px;
            font-size: 19px;
            cursor: pointer;
        }
    }

    .modal-body{
        padding: 20px 0px;
        float: left;
        width: 100%;
        // background: #ffffff;

        .popup_text {
            color: #ffffff;
            text-align: center;
            font-size: 26px;
            padding: 20px 20px;
            font-weight: 600;
            word-break: break-word;
        }
        p.popup_text_note {
            color: #da2753;
            text-align: center;
            font-size: 16px;
            padding: 0px 20px 20px;
            font-weight: 600;
            word-break: break-word;
        }
        .popup_text1 {
            color: $primary-color;
            // text-align: center;
            font-size: 20px;
            // padding: 20px 20px;
            margin-block-end: 0;
            margin-block-start: 0;
            font-weight: 600;
            word-break: break-word;
            padding: 10px 20px 20px;
        }
        .error {
            color: #000000;
            max-width: 600px;
        }
    }
    .modal-footer {
        justify-content: center !important;
        // border-top: 1px solid #cacaca;
        float: left;
        width: 100%;
        padding: 15px 0px 20px;
        
        .footer {
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
        }
    }
}

.selectModal {
    .modal-header {
        background-color: $primary-color;
        height: auto;
        text-align: center;

        .modal-header-title {
            color: #ffffff;
            font-size: 25px;
            font-weight: 300;
            line-height: 50px;
        }
    }
    .modal-body {
        float: left;
        width: 100%;
        height: 350px;
        padding: 0px;

        .select-options.bottom {
            border: 0px;
            box-shadow: 0px 0px 0px 0px;
            max-height: 350px !important;
        }
    }
}

.templateModal .modal-body {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 80px 50px 50px;
    width: 600px;
    text-align: center;
}

.durationModal {
    width: 480px;
    height: 241px;
    background-color: #000000;
    box-shadow: 0px 0px 0px 1px #ffffff;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .text {
        font-size: 16px;
        line-height: 21.86px;
        font-weight: 800;
        color: #ffffff;
        text-align: center;
    }
    .form_row {
        display: flex;
        justify-content: center;
        align-items: center;
        .form_group::after {
            content: ':';
            color: #ffffff;
            position: absolute;
            right: -30px;
            top: 4px;
            font-size: 33px;
            line-height: 48px;
            width: 30px;
            text-align: center;
        }
        .form_group:last-child::after {
            content: '';
            margin-right: 0px;
        }
        .form_group {
            width: 48px;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            margin-right: 30px;
            label {
                font-size: 10px;
                line-height: 13px;
                font-weight: 500;
                text-align: center;
            }
            .formInput {
                padding: 0px;
                width: 48px;
                font-size: 24px;
                line-height: 26px;
                letter-spacing: 2.85px;
                margin: 0;
                height: 48px;
                text-align: center;
                vertical-align: middle;
                background: transparent;
            }
        }
    }
    .error {
        color: $primary-color;
        font-size: 12px;
        height: 16px;
    }
    .footer {
        display: flex;
        justify-content: center;
        button {
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            width: 115px;
        }
        button:first-child {
            margin-right: 10px;
        }
        .btns-default {
            background: transparent;
            color: #ffffff;
            border-color: #ffffff !important;
        }
    }
}
.errorModalUI {
    background: $background;
    border: 1.5px solid $secondary-color1;
    border-radius: 12px;
    // width: 500px;
    // min-height: 241px;
    // background-color: #000000;
    // box-shadow: 0px 0px 0px 1px #ffffff;
    // box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    // border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding: 30px;

    .body {
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: space-evenly;
        flex-grow: 1;
        .text {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.02em;
            color: $secondary-text-color;
        }
        .note {
            font-size: 14px;
            line-height: 27px;
            font-weight: 800;
            color: #f32b5d;
        }
    }
    .footer {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        button:first-child {
            margin-right: 10px;
        }
    }
}

.formModal {
    width: 480px;
    min-height: 241px;
    background-color: #000000;
    box-shadow: 0px 0px 0px 1px #ffffff;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    justify-content: space-between;
    padding: 20px;

    .modal-header {
        font-size: 25px;
        font-weight: 600;
        line-height: 40px;
        color: $primary-color;
    }

    form {
        padding-bottom: 0px !important;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .footer {
            display: flex;
            justify-content: center;
        }
    }
}
.addFormModal {
    height: 308px;
    width: 540px;
    padding: 0;
    background: #242424;
    border: 1px solid #333333;
    box-sizing: border-box;
    box-shadow: 0px 12px 24px #00000080;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-header {
        height: 66px;
        background: #121212;
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 21px;
    }

    form {
        padding: 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .footer {
            display: flex;
            justify-content: center;
        }
    }
}