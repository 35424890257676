@import './../../Sass/Variables';
@import './../../Sass/Mixins';


.Home {
    background-color: $_white;

    .pinkDiv {
        padding: 110px;
        position: relative;
        background-color: $_pink;     
        .logoImage {
            position: absolute;
            top: 15%;
            left: 7%;
            @include dimensions( 60px, 120px ); 
        }  
    }
    .containerDiv.mainPage {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        background: #272727;
        .version {
            position: absolute;
            bottom: 2px;
            right: 7px;
            color: #868686;
            font-size: 10px;
        }
        .containerBox{
            height: fit-content;
            max-height: 90%;
            display: flex;
            width: 70%;
            flex-wrap: nowrap;
            // background: #FFFFFF;
            box-shadow: 0px 4px 6px -4px #18274b1f, 0px 8px 8px -4px #18274b14;
            border-radius: 8px;
            flex-direction: column;
            overflow: scroll;
            .containerBox_innerBox {
                justify-content: space-between;
                flex-wrap: nowrap;
                display: flex;
                justify-content: space-between;
                .leftDiv {
                    display: flex;
                    flex-direction: column;
                    width: 60%;
                    height: 100%;
                    overflow: scroll;
                    padding: 33px 37px;
                    gap: 18px;
                    background: #FFFFFF;
                    h2 {
                        font-style: normal;
                        font-weight: 900;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: 0.2px;
                        color: #272727;
                        margin: 0px;
                    }
                    .stepDiv {
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            /* or 100% */
                            letter-spacing: 0.2px;
                            color: #272727;
                            .icon_div {
                                background: #3D3D3D;
                                border-radius: 4px;
                                padding: 2px 4px;
                                margin: 0px 4px;
                                // .scanIcon {
                                // }
                            }
                        }
    
                    }
                    .videoContainer {
                        display: flex;
                        width: 100%;
                        padding-top: 56.7%;
                        position: relative;
                        overflow: hidden;
                        .videoTagDiv {
                            width: 100%;
                            position: absolute;
                            top: 0;
                            height: 100%;
                            .playIcon_div {
                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: linear-gradient(0deg, #00000066, #00000066);
                                border: 1px solid #E3E3E3;
                                border-radius: 5px;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                            }
                            .playIcon {
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                @include dimensions(72px, 72px);
                                position: absolute;
                                border-radius: 50px;
                                // background: rgba(0, 0, 0, 0.5);
                                background: #FFFFFF;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                z-index: 2;
                                svg {
                                    font-size: 30px;    
                                }
                            }
                            .videoImage {
                                min-width: 100%;
                                width: 100%;
                            }
                            .videoImage.preview {
                                position: absolute;
                            }
                            .videoImage.hide {
                                opacity: 0;
                            }
                        }
                    }
                }
                .rightDiv {
                    width: 40%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #F1F1F1;
                    // height: 100%;
                    // border-radius: 0px 8px 8px 0px;
                    flex-direction: column;
                    .scan {
                        border: 1px solid #000000;
                        border-radius: 5px;
                        padding: 3px;
                        display: flex;
                        align-items: center;
                    }
                    .scanCode {
                        font-style: normal;
                        font-weight: 900;
                        font-size: 14px;
                        line-height: 16px;
                        /* identical to box height, or 114% */
                        letter-spacing: 0.2px;
                        color: #272727;
                    }
                }
            }
            .footer_div1 {
                background: #303030;
                height: 8px;
                width: 100%;
            }
            .footer_div2 {
                background: #454545;
                height: 9px;
                width: 100%;
                // border-radius: 0px 0px 8px 8px;
            }
        }
    }
    .oldData {
        position: relative;
        height: 1000px;
        .containerBox {
            width: 70%;
            position: absolute;
            top: -10%;
            left: 15%;  
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            background-color: #FFFFFF;
            @include flex(column, nowrap, center, center);    
            box-shadow: 3px 4px 4px 2px rgba(198,198,198,0.5);
            .scanContainer {
                padding: 40px 0px;
                width: 100%;
                @include flex(row, wrap, inherit, center);    
                    .stepsDiv {
                        flex-basis: 52%;
                        .imagesIconsClass {
                            display: flex;
                            align-items: center;
                            color: rgba(0,0,0,0.58);
                            @include font(18px, 500);
                        }
                        .scanIcon {
                            margin-left:5px;
                            margin-right:5px;
                            @include dimensions(20px, 20px);
                        }
                        h2 {
                            color: #0C0C0C;
                            @include font(35px, 900);
                        }
                        p { 
                            color: rgba(0,0,0,0.58);
                            @include font(18px, 500);
                        }
                    }
                    .scan {
                        // flex-basis: 250px;
                        padding: 20px;
                         @include flex(column, nowrap, center, center);          
                    }
            }
            .videoContainer {
                padding: 2% 10% 10% 10%;
                width: 100%;
                @include flex(column, nowrap, space-between, center);          
                .videoTagDiv {
                    position: relative;
                    
                    // @include dimensions(430px, 100%);
                    .playIcon {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width:  100px;
                        height: 100px;
                        position: absolute;
                        border-radius: 50px;
                        background: rgba(0, 0, 0, 0.5);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            font-size: 35px;    
                        }
                    }
                    .videoImage {
                        box-shadow: 1px 1px 4px 2px rgba(198,198,198,0.5);
                        @include dimensions(100%, 100%);
                        object-fit: cover
                    }

                }
                p{
                    color: $_pink;
                    @include font(14px, 400); 
                }
            }
    
        }
    }
    .footer {
        padding: 20px 0px;            
        background-color: $_black;
        @include flex(row, nowrap, center, center);
        p {
            color: $_white;
            @include font(15px, 400);
        }
    }
} 

@media only screen and (min-width: 320px) and (max-width: 479px){
    .Home {
     
        .pinkDiv {
            padding: 20px 0px;
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
            .logoImage {
                position: relative !important;
                top:0;
                left:0;
                @include dimensions( 50px, 100px ); 
            }  
        }     
        .containerDiv {
            position: relative;
            height:auto;
            .containerBox {
                width: 100%;  
                position: relative !important; 
                top:0;
                left:0;
                box-shadow: none;
                .scanContainer {
                    padding: 30px 0px;
                    width: 100%; 
                    @include flex(column, wrap, inherit, center);    
                        .stepsDiv {
                            flex-grow: 1;
                            padding:0px 10px;
                            .imagesIconsClass {
                                @include font(11px, 500);
                            }
                            .scanIcon {
                                margin-left:5px;
                                margin-right:5px;
                                @include dimensions(15px, 15px);
                            }
                            h2 {
                                color: #0C0C0C;
                                @include font(17px, 900);
                            }
                            p { 
                                color: rgba(0,0,0,0.58);
                                @include font(11px, 500);
                            }
                        }
                        .scan {
                            flex-grow: 1;
                            padding: 10px;
                            // svg {
                            //     @include dimensions(230px !important, 230px !important);
                            // }

                        }
                }
                .videoContainer {
                    // padding: 2% 10% 7% 10%;
                    // .videoTagDiv {
                    //     @include dimensions(240px, 100%);    
                    // }
                    p{
                        color: $_pink;
                        @include font(14px, 400); 
                    }
                }
        
            }
        }
        .footer {
            padding: 20px 0px;            
            p {
                color: $_white;
                @include font(15px, 400);
            }
        }
    }
 }

@media only screen and (min-width: 480px) and (max-width: 767px){ 
    .Home {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        .pinkDiv {
            padding: 20px 0px;
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
            .logoImage {
                position: relative !important;
                top:0;
                left:0;
                @include dimensions( 50px, 100px ); 
            }  
        }     
        .containerDiv {
            position: relative;
            height:auto;
            .containerBox {
                width: 100%;  
                position: relative !important; 
                top:0;
                left:0;
                box-shadow: none;
                .scanContainer {
                    padding: 30px 0px;
                    width: 100%; 
                    @include flex(column, wrap, inherit, center);    
                        .stepsDiv {
                            flex-grow: 1;
                            .imagesIconsClass {
                                @include font(11px, 500);
                            }
                            .scanIcon {
                                margin-left:5px;
                                margin-right:5px;
                                @include dimensions(15px, 15px);
                            }
                            h2 {
                                color: #0C0C0C;
                                @include font(23px, 900);
                            }
                            p { 
                                color: rgba(0,0,0,0.58);
                                @include font(15px, 500);
                            }
                        }
                        .scan {
                            flex-grow: 1;
                            padding: 10px;
                            // svg {
                            //     @include dimensions(250px !important, 250px !important);
                            // }

                        }
                }
                .videoContainer {
                    // padding: 2% 10% 7% 10%;
                    // .videoTagDiv {
                    //     @include dimensions(310px, 100%);    
                    // }
                    p{
                        color: $_pink;
                        @include font(14px, 400); 
                    }
                }
        
            }
        }
        .footer {
            padding: 20px 0px;            
            p {
                color: $_white;
                @include font(15px, 400);
            }
        }
    }
}

@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) 
and (orientation : portrait) {

    .Home {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height:100vh;
        .pinkDiv {
            padding: 70px;
            .logoImage {
                top: 15%;
                left: 7%;
                @include dimensions( 50px, 100px ); 
            }  
        }     
        .containerDiv {
            position: relative;
            flex-grow: 1;
            .containerBox {
                width: 70%;
                top: -6%;
                left: 15%;  
                .scanContainer {
                    padding: 30px 0px;
                    width: 100%; 
                    @include flex(row, wrap, inherit, center);    
                        .stepsDiv {
                            .imagesIconsClass {
                                @include font(11px, 500);
                            }
                            .scanIcon {
                                margin-left:5px;
                                margin-right:5px;
                                @include dimensions(15px, 15px);
                            }
                            h2 {
                                color: #0C0C0C;
                                @include font(15px, 900);
                            }
                            p { 
                                color: rgba(0,0,0,0.58);
                                @include font(11px, 500);
                            }
                        }
                        .scan {
                            padding: 10px;
                            // svg {
                            //     @include dimensions(170px !important, 170px !important);
                            // }

                        }
                }
                .videoContainer {
                    // padding: 2% 10% 7% 10%;
                    // .videoTagDiv {
                    //     @include dimensions(310px, 100%);    
                    // }
                    p{
                        color: $_pink;
                        @include font(14px, 400); 
                    }
                }
        
            }
        }
        .footer {
            padding: 20px 0px;            
            p {
                color: $_white;
                @include font(15px, 400);
            }
        }
    }

}

@media screen and (min-width: 768px)and (max-width: 1023px) and (orientation : landscape) { 
    .Home {
        .pinkDiv {
            padding: 70px;
            .logoImage {
                top: 15%;
                left: 7%;
                @include dimensions( 50px, 100px ); 
            }  
        }     
        .containerDiv {
            position: relative;
            height: 700px;
            .containerBox {
                width: 70%;
                top: -6%;
                left: 15%;  
                .scanContainer {
                    padding: 30px 0px;
                    width: 100%; 
                    @include flex(row, wrap, inherit, center);    
                        .stepsDiv {
                            .imagesIconsClass {
                                @include font(11px, 500);
                            }
                            .scanIcon {
                                margin-left:5px;
                                margin-right:5px;
                                @include dimensions(15px, 15px);
                            }
                            h2 {
                                color: #0C0C0C;
                                @include font(15px, 900);
                            }
                            p { 
                                color: rgba(0,0,0,0.58);
                                @include font(11px, 500);
                            }
                        }
                        .scan {
                            padding: 10px;
                            // svg {
                            //     @include dimensions(170px !important, 170px !important);
                            // }

                        }
                }
                .videoContainer {
                    // padding: 2% 10% 7% 10%;
                    // .videoTagDiv {
                    //     @include dimensions(310px, 100%);    
                    // }
                    p{
                        color: $_pink;
                        @include font(14px, 400); 
                    }
                }
        
            }
        }
        .footer {
            padding: 20px 0px;            
            p {
                color: $_white;
                @include font(15px, 400);
            }
        }
    }
}

@media screen and (min-width: 1024px)and (max-width: 1223px)  { 
    .Home {
        .pinkDiv {
            padding: 85px;
            .logoImage {
                top: 15%;
                left: 7%;
                @include dimensions( 50px, 100px ); 
            }  
        }     
        .containerDiv {
            position: relative;
            height: 850px;
            .containerBox {
                width: 70%;
                top: -8%;
                left: 15%;  
                .scanContainer {
                    padding: 30px 0px;
                    width: 100%; 
                    @include flex(row, wrap, inherit, center);    
                        .stepsDiv {
                            .imagesIconsClass {
                                @include font(15px, 500);
                            }
                            .scanIcon {
                                margin-left:5px;
                                margin-right:5px;
                                @include dimensions(15px, 15px);
                            }
                            h2 {
                                color: #0C0C0C;
                                @include font(22px, 900);
                            }
                            p { 
                                color: rgba(0,0,0,0.58);
                                @include font(15px, 500);
                            }
                        }
                        .scan {
                            padding: 20px;
                            // svg {
                            //     @include dimensions(210px !important, 210px !important);
                            // }

                        }
                }
                .videoContainer {
                    // padding: 2% 10% 7% 10%;
                    // .videoTagDiv {
                    //     @include dimensions(370px, 100%);    
                    // }
                    p{
                        color: $_pink;
                        @include font(14px, 400); 
                    }
                }
        
            }
        }
        .footer {
            padding: 20px 0px;            
            p {
                color: $_white;
                @include font(15px, 400);
            }
        }
    }
}
@media screen and (min-width: 1224px)and (max-width: 1500px) { 
    .Home {
        .pinkDiv {
            padding: 85px;
            .logoImage {
                top: 15%;
                left: 7%;
                @include dimensions( 50px, 100px ); 
            }  
        }     
        .containerDiv {
            position: relative;
            height: 900px;
            .containerBox {
                width: 70%;
                top: -8%;
                left: 15%;  
                .scanContainer {
                    padding: 40px 0px;
                    width: 100%; 
                    @include flex(row, wrap, inherit, center);    
                        .stepsDiv {
                            .imagesIconsClass {
                                @include font(18px, 500);
                            }
                            .scanIcon {
                                margin-left:5px;
                                margin-right:5px;
                                @include dimensions(20px, 20px);
                            }
                            h2 {
                                color: #0C0C0C;
                                @include font(26px, 900);
                            }
                            p { 
                                color: rgba(0,0,0,0.58);
                                @include font(16px, 500);
                            }
                        }
                        .scan {
                            padding: 20px;
                            // svg {
                            //     @include dimensions(230px !important, 230px !important);
                            // }

                        }
                }
                .videoContainer {
                    // padding: 2% 10% 7% 10%;
                    // .videoTagDiv {
                    //     @include dimensions(370px, 100%);    
                    // }
                    p{
                        color: $_pink;
                        @include font(14px, 400); 
                    }
                }
        
            }
        }
        .footer {
            padding: 20px 0px;            
            p {
                color: $_white;
                @include font(15px, 400);
            }
        }
    }
}