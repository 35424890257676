@import '../../../Sass/Mixins.scss';
@import '../../../Sass/Variables.scss';

.IvsVideoBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -111111;   
    .VideoTag {
        height: 100%;
        background-color: #1E1E1E;
        width: 100%;
        object-fit: cover;
    }
}
.backMessage {
    background-color: #1E1E1E;
    position: absolute;
    z-index: -111111;   
    @include dimensions(100%, 100%);
    @include flex(row, nowrap, center, center);
    .streamMessage {
        color: $_white;
        @include font(14px, 300);
        margin: 0;
        z-index: 99999;
    }
}


// @media only screen and (min-width: 320px) and (max-width: 479px) and (orientation : portrait) {
//     .IvsVideoBox {
//         width: 100%;
//         height: 100% !important;
//         position: fixed;
//         z-index: -111111;   
//         .VideoTag {
//             height: 100%;
//             background-color: #1E1E1E;
//             width: 100%;
//             object-fit: cover;
//         }
//     }
// }

// @media only screen and (min-width: 480px) and (max-width: 767px) and (orientation : portrait) {
//     .IvsVideoBox {
//         width: 100%;
//         height: 100% !important;
//         position: fixed;
//         z-index: -111111;   
//     }
//  }