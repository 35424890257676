@import '../../../Sass/Variables.scss';

.form_row {

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;

    .form_group{
        width:48%;
    }
}

.form_row3 {

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: 10px;
    // justify-content: space-between;
    gap: 20px;

    .form_group{
        width:32%;
        // label{
        //     color: #767676 !important;
        // }
    }
}

.form_rows {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;

    .form_group{
        width:48%;
    }
}


.form_group {
    width: 100%;
    padding: 5px 0px 7px 0px;
    position: relative;
    min-height: 76px;
    &.image_div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        label {
            margin-bottom: 0px;
        }
    }
    .input_outer {
        position: relative;
        height: 57px;
        .showCount {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 10px;
            font-family: 'Cabin' !important;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0;
            color: #767676;
        }        
    }
    .tag_form {
        flex-grow: 1;
        .rti--container {
            // padding: 0px;
            border: 1px solid #434343;
            border-radius: 5px;
            background: #121212;
            min-height: 57px;
            --rti-main: #F22B5D;
        }
        .rti--input {
            width: 50% !important;
            background: transparent;
            border: 0;
            outline: 0;
            font-size: inherit;
            line-height: inherit;
            height: calc(43px - (2 * var(--rti-s)));
        }
    }

    &.group_checkBox {
        min-height: 50px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        .checkmarkContainer  {
            margin: 0px !important;
        }
    }

    svg {
        position: absolute;
        top: 45px;
        right: 16px;
    }

    .fa-info-circle {
        position: initial !important;
        margin-left: 5px;
        color: $primary-color;
    }

    .fa-chevron-down {
        color: #f32b5d;
        top: 50px;
    }

    .char_limit {
        width: 100%;
        color: $white-color;
        text-align: right;
        // float: left;
        margin-top: 16px;
        display: block;
    }
    .calenderIcon {
        position: absolute;
        top: 0px;
        bottom: 0px;
        margin: auto;
        right: 16px;
        width: 20px;
        height: 20px;
    }
    img {
        position: absolute;
        top: 50px;
        right: 16px;
        width: 20px;
        height: 20px;
    }

    .tickIcon {
        width: 20px !important;
        color: #F22B5D !important;
        position: absolute;
        right: 10px;
        height: 20px;
        bottom: 0;
        margin: auto;
        top: 0;
    }
    .showPassword {
        width: 20px !important;
        color: #F22B5D !important;
        position: absolute;
        right: 10px;
        height: 20px;
        bottom: 0;
        margin: auto;
        top: 0;
        cursor: pointer;
    }

    & > label {
        margin-bottom: 5px !important;
    }
    label {
        color: $white-color;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        font-family: Avenir;
        line-height: 29px;
        margin-bottom: 5px;
        // display: inline-block;
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
            position: inherit;
        }

        .infoIcon {
            // margin: 0 0 0px 10px;
            // vertical-align: middle;
            &_svg {
                // margin: 0 0 -3px 0px;
                position: initial !important;

            }
        }
    }
    .radio_group {
        display: flex;
        gap: 40px;
        label {
            margin-bottom: 0px !important;
        }
        .checkmark_div {
            width: 23px !important;
            height: 23px !important;
            // input:checked ~ .checkmark:after {
            //     display: none !important;
            // }
            // input:checked ~ .checkmark {
            //     background: $primary-color;
            // }
          
            // .checkmark {
            //     // width: 100% !important;
            //     // height: 100% !important;
            //     // border: 2px solid #C0BDCC !important;
            //     // background: transparent !important;
            // }
        }
    }
    .radio_group {
        display: flex;
        gap: 40px;
    }

    input.formInput {
        &:focus, &.inputError {
            border-color: $primary-color;
        }
        &:disabled {
            cursor: not-allowed;
            background: #848484 !important;
        }
    }

    input[type="text"],input[type="number"],input[type="password"] { 
        box-sizing: border-box;
        height: $formInput-height;
        background-color: $formInput-background;
        padding-left: 12px;
        padding-right: 47px;
        width: 100%;
        border: 1px solid $formInput-border;
        border-radius: 5px;
        width:100%;
        color: $white-color;
        font-size: 16px;
        font-weight: 500;
    }

    span { 
        font-size: 13px;
    }

    .error {
        color: #F22B5D !important;
        background: transparent !important;
        font-size: 12px;
        float: right;
        font-weight: 300;
        &.errorPos {
            position: absolute;
            bottom: -18px;
            right: 20px;
        }
    }
    .error::before {
        content: "*";
    }
    .labelRequired::after {
        content: "*";
        color: $primary-color; 
    }

    textarea { 
        box-sizing: border-box;
        background-color: $formInput-background;
        padding: 15px;
        width: 100%;
        border: 1px solid $formInput-border;
        border-radius: 5px;
        width:100%;
        color: $white-color;
        font-size: 15px;
        font-weight: 100;
        line-height: 22px;
        font-family: Avenir;
        height: 148px;
    }

    select { 
        box-sizing: border-box;
        height: 46px;
        background-color: $black-color;
        padding-left: 12px;
        padding-right: 47px;
        width: 100%;
        border: 1px solid $formInput-border;
        border-radius: 5px;
        width:100%;
        color: $white-color;
        font-size: 16px;
        font-weight: 500;
    }
}


/* ===============  CUSTOM CHECKBOX ============= */

.role_content {

    padding: 5px 0px 0px 30px;

    .role_title {
        color: $white-color;
        font-size: 18px;
        margin-left: 10px;
        letter-spacing: 0;
    }
    
    .role_subtitle {
        color: $white-color;
        font-size: 14px;
        letter-spacing: 0;
        margin-left: 10px;
        line-height: 0px;
      }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 16px;
        left: -30px;
        border: 2px solid #808080;
        height: 27px;
        width: 30px;
        border-radius: 4px;
        background-color: $black-color;
    }

    .checkmark:hover {
        background-color: $primary-color;
        border-color: $primary-color
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }


    /* The role_container */
.role_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .role_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  
    /* When the checkbox is checked, add a pink background */
  .role_container input:checked ~ .checkmark {
    background-color: $primary-color;
    border-color: $primary-color;
  }
  

  /* Show the checkmark when checked */
  .role_container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .role_container .checkmark:after {
    left: 9px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

/* ====================================== */

}


@media screen and (min-width: 768px)and (max-width: 1023px)  {

    .form_group  {

        label {
            font-size: 12px;
        }

        input {
            height: 40px;
            padding-left: 12px;
            padding-right: 36px;
            width: 100%;
            width: 100%;
            color: $white-color;
            font-size: 14px;
            font-weight: 500;
        }

        svg {
            top: 44px;
        }

        img {
            position: absolute;
            top: 39px;
            right: 12px;
            width: 20px;
            height: 20px;
        }
    }

    .role_content {
        .role_title {
            font-size: 17px;
            margin-left: 10px;
        }
    
        .role_subtitle {
            font-size: 12px;
            margin-left: 10px;
        }
    }


}

@media screen and (min-width: 1024px)and (max-width: 1223px) { 

    .role_content {
        .role_title {
            font-size: 17px;
            margin-left: 10px;
        }
    
        .role_subtitle {
            font-size: 12px;
            margin-left: 10px;
        }
     }

  }

  @media screen and (min-width: 1224px)and (max-width: 1400px) {  

    .role_content  {
        .role_title {
            font-size: 17px;
            margin-left: 10px;
        }
    
        .role_subtitle {
            font-size: 13px;
        }
    }

  }
