@import '../../../Sass/Mixins.scss';
@import '../../../Sass/Variables.scss';


.ftr.hideUnhideDiv {
    bottom: 75px;
    left: 0;
    right: 0px;
    margin: auto;
}
.ftr.hideUnhideDiv.active {
    bottom: 0px;
}
.ftr.hide {
    height: 0px;
    .overflow_hidden {
        height: 0px;
    }
    .statsBox {
        overflow: hidden;
    }
}
.hideUnhideDiv {
    width: 52px;
    height: 34px;
    background: $background-color1;
    backdrop-filter: blur(12px);
    border-radius: 8px 8px 0px 0px;
    // transform: matrix(0, 1, 1, 0, 0, 0);
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    border: 1px solid #ffffff;
    border-bottom: 0px;
    transition: bottom .2s 0s, right .5s 0s, left .5s 0s;
    cursor: pointer;
    .lightSvg {
        flex-grow: 1;
        transform: rotate(180deg);
        path {
            fill: #505050;
        }
    }
    .darkSvg {
        flex-grow: 1;
        transform: rotate(180deg);
        path {
            fill: #ffffff;
        }
    }
}
.hideUnhideDiv.active {
    svg {
        transform: rotate(0deg);
    }
}

.left.hideUnhideDiv {
    top: 154px;
    left: 208px;
    // left: 238px;
    margin: auto;
    transform: rotate(90deg);
}
.left.hideUnhideDiv.active {
    left: -10px;
}
.left.hide {
    width: 0px;
    border-width: 0px;
    padding: 0px;
    transition: width .5s 0s, padding 0s .4s, border-width 0s 0.4s ease-in-out;
}
.Casting {
    .right.hideUnhideDiv {
        top: 154px;
        right: 294px;
        margin: auto;
        transform: rotate(270deg);
    }
    .chatright.hideUnhideDiv {
        top: 154px;
        right: 294px;
        margin: auto;
        transform: rotate(270deg);
    }
}
.right.hideUnhideDiv {
    top: 154px;
    right: 238px;
    margin: auto;
    transform: rotate(270deg);
}
.chatright.hideUnhideDiv {
    top: 154px;
    right: 294px;
    margin: auto;
    transform: rotate(270deg);
}
.chatright.hideUnhideDiv.active,.right.hideUnhideDiv.active {
    right: -10px;
}
.chatright.hide,.right.hide {
    width: 0px !important;
    border-width: 0px;
    transition: width .5s 0s, border-width 0s 0.4s ease-in-out;
}
@media screen and (max-width: 1500px) {
    .right.hideUnhideDiv {
        right: 216px;
    }
    .left.hideUnhideDiv {
        left: 208px;
    }
}