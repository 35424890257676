.paddings {
    padding: 15px 10px;
    position: relative;
    min-height: 200px;
    display: inline-flex;
    width: 200px;
    
    .stream {
        padding: 0px;
        // border: 3px solid #FF0040;
        border-radius: 2px 2px 0px 0px;
        width:100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .number {
            // z-index: 2;
            height: 25px;
            width: 16px;
            color: #FFFFFF;
            font-family: Avenir;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            // text-shadow: 1px 1px 0px rgb(50 50 50);
            position: absolute;
            top: 8px;
            left: 8px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #000000;
            -webkit-text-fill-color: white;
        }  
        .headers {
            background-color: #000000;
            box-sizing: border-box;
            // border: 1px solid #323232;
            border-radius: 2px 2px 0px 0px;
            width: 100%;
            height: calc(100% - 40px);

            .placeHolder {
                position: absolute;
                width: 100%;
                height: calc(100% - 40px);
                object-fit: contain;;
                // object-fit: cover;
                // z-index: 1;
            }
    
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .footer {
            box-sizing: border-box;
            height: 40px;
            width: 100%;
            padding: 5px 8px;
            // background-color: #FF0040;
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            .image_name {
                display: flex;
                align-items: center;
    
                .image {
                    box-sizing: border-box;
                    height: 31px;
                    width: 31px;
                    border: 1px solid #FFFFFF;
                    border-radius: 50%;
                    object-fit: cover;
                }
    
                .name {
                    height: 18px;
                    width: 46px;
                    color: #FFFFFF;
                    font-family: Avenir;
                    font-size: 14px;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 18px;
                    margin-left: 9px;
                }          
            }
            .icon_number {
                width: 120px;
                justify-content: space-between;
                display: flex;
    
                .numb {
                    color: #FFFFFF;
                    font-family: Avenir;
                    font-size: 18px;
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 30px;
                    display: flex;
                    min-width: 50px;
                    justify-content: flex-end;
            
                    .icon {
                        font-size: 10px;
                        // height: 10.28px;
                        // width: 7.77px;
                        // background-color: #FFFFFF;
                        // margin-left: 4px;
                    }
                }
            }
    
        }
    }
}

@media screen and (max-width: 1023px) {
    .paddings {
        width: calc(100%/3);
        // max-height: 300px;

        .stream {

            .number {
                font-size: 18px;
            }

            .footer {
                padding: 3px;

                .image_name {

                    .image {
                        width: 26px;
                        height: 26px;
                    }

                    .name {
                        font-size: 12px;
                        width: 36px;
                        margin-left: 4px;
                    }
                }

                .icon_number {
                    width: 85px;

                    .numb {
                        font-size: 14px;
                        min-width: 40px;

                        .icon {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px)and (max-width: 1223px) {
    .paddings {
        width: calc(100%/4);
        // max-height: 300px;

        .stream {

            .number {
                font-size: 18px;
            }

            .footer {
                padding: 3px;

                .image_name {

                    .image {
                        width: 26px;
                        height: 26px;
                    }

                    .name {
                        font-size: 12px;
                        width: 36px;
                        margin-left: 4px;
                    }
                }

                .icon_number {
                    width: 85px;

                    .numb {
                        font-size: 14px;
                        min-width: 40px;

                        .icon {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }

}
@media screen and (min-width: 1224px)and (max-width: 1488px) {
    .paddings {
        .stream {

            .number {
                font-size: 18px;
            }

            .footer {
                padding: 3px;

                .image_name {

                    .image {
                        width: 26px;
                        height: 26px;
                    }

                    .name {
                        font-size: 12px;
                        width: 36px;
                        margin-left: 4px;
                    }
                }

                .icon_number {
                    width: 85px;

                    .numb {
                        font-size: 14px;
                        min-width: 40px;

                        .icon {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }

}
