// @use "../../../Sass/Variables.scss" as VAR;
@import '../../../Sass/Mixins.scss';
@import '../../../Sass/Variables.scss';

.overflow_hidden {
    overflow: hidden;
}

.width100 {
    width: 100%;
}

.coach_div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    z-index: -2;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.stopWatch {
    display: flex;
    align-items: center;
    @include dimensions(auto, 229px);
    justify-content: space-between;
    color: #ffffff;
    @include absolute( 28px, 31px, inherit, inherit);
    .title {        
        // text-shadow: 2px 2px 4px #000000;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.01em;
        color: #FFFFFF;
    }
    .time {
        display: flex;
        justify-content: space-between;
        @include dimensions(auto, 139px);
        // margin-right: 20px;
        .timeUI {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            position: relative;
            // text-shadow: 2px 2px 4px #000000;

            .number {
                @include dimensions(57px, 57px);
                border: 0.6px solid;
                text-align: center;
                border-radius: 50%;
                backdrop-filter: blur(3px);
                float: left;
                // margin-right: 8px;
                font-weight: 600;
                font-size: 32px;
                line-height: 39px;
                letter-spacing: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .number.light {
                background: linear-gradient(180deg, #E5E5E5 0%, rgba(229, 229, 229, 0) 112.28%)
            }
            .number.dark {
                background: linear-gradient(180deg, #3D3D3D 0%, rgba(61,61,61,0) 112.28%);    
            }
        }

        .timeUI:first-child:after {
            right: -18px;
            content: ":";
            position: absolute;
            font-size: 44px;
            // text-shadow: 2px 2px 4px #000000;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 30px;
        }
    }
}

.pinUser.noState {
    // padding-right: 0px;
    .pin-noState {
        @include dimensions(57px, 100%);
    	display: flex;
	    flex-direction: row;
        align-items: center;
        gap: 10px;
    	justify-content: space-between;
        .userPin {
            cursor: default;
        }
        p {
            margin: 0px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $secondary-text-color;
            letter-spacing: 0.5px;
        }
    }
}
.pinVideoView.noState {
    // padding-right: 0px;
    .pin-noState {
        // padding-left: 10px;
        @include dimensions(57px, 100%);
    	display: flex;
	    flex-direction: row;
        align-items: center;
        gap: 10px;
    	justify-content: space-between;
        .userPin {
            cursor: default;
        }
        p {
            margin: 0px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $secondary-text-color;
            letter-spacing: 0.5px;
        }
    }
}
.pinUser {
    @include dimensions(auto, 218px);
    // background: rgba(0, 0, 0, 0.6);
    background: $background-color1;
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(12px);
    border-radius: 0px 16px 16px 0px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 0px;
    overflow: hidden;
    transition: width .5s 0s;
    @include absolute(130px, inherit, inherit, 0px);
    .userUi.me {
        background-color: #ffffff;
        .userDetail .userName {
            text-shadow: 2px 2px 4px #ffffff;
            color: var(--dynamic-variable) !important;
        }
    }
    .userUi {
        @include dimensions(53px, auto);
        padding: 0px 15px;
        background: $secondary-color1;
        border: 1px solid #767676;
        border-radius: 4px;
        margin: 5px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .userDetail {
            display: flex;
            align-items: center;
            max-width: calc(100% - 26px);
            padding-right: 2px;
            .userPicture {
                @include dimensions(35px, 35px);
                min-width: 35px;
                background: #ffffff;
                border-radius: 50%;
                margin-right: 10px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover !important;
                }
            }
            .userName {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: $secondary-text-color;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;    
            }
        }
    }
    .userUi:first-child {
        margin-top: 0px;
    }
    .userUi:last-child {
        margin-bottom: 0px;
    }
}

.logoBox {
    @include flex(column, nowrap, space-evenly, center);
    @include dimensions(70px, 130px);
    @include absolute(21px, inherit, inherit, 18px);
    // background: rgba(0, 0, 0, 0.6);
    // backdrop-filter: blur(12px);
    // border: 1px solid #FFFFFF;
    // border-radius: 8px;
    .logoImage{
        // @include dimensions(37px, 60px);
        @include dimensions(86%, 90%);
        object-fit: scale-down;
    }        
}

.boardContainers {
    position: absolute;
    top: 130px;
    right: 0px;
    bottom: 98px;
    left: auto;
    @include dimensions(auto, $width);
    max-height: 655px;
    // background: rgba(0,0,0,0.6);
    background: $background-color1;
    // opacity: 0.6;
    border: 1px solid #FFFFFF;
    border-radius: 16px 0px 0px 16px;
    // transform: matrix(1, 0, 0, -1, 0, 0);
    border-right: 0px;
    // padding: 13px 14px;
    @include flex(column, nowrap, space-between, normal);
    transition: width .5s 0s;
    gap: 12px;
    overflow: hidden;
    // position: relative;
    .member_view_div.hide {
        display: none;
        opacity: 0;
    }
    .member_view_div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        overflow: scroll;
        padding: 0px 14px 13px;
        gap: 5px;
        .video-canvas {
            display: flex;
            height: auto;
            position: absolute;
            z-index: 3;
            top: 0;
        }
        .user_container {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            flex-grow: 1;
            z-index: 4;
            gap: 10px;
            position: relative;
        }
    }
    .tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include dimensions(38px, auto);
        min-height: 38px;
        // margin-bottom: 12px;
        margin: 13px 14px 0px;
        padding: 0px;
        border-radius: 8px;
        // overflow: hidden;
        // background
        border: 0.5px solid $box-border-color;
        .memberIcon1 {
            @include dimensions(100%, 50%);
            display: flex;
            justify-content: center;
            align-items: center;
            background: $primary-color1;
            border-radius: 7.5px 0 0 7.5px;
            cursor: pointer;

            &.fullWidth {
                width: 100%;
                border-radius: 7.5px;
            }

            svg {
                path {
                    fill: #FFFFFF
                }
            }

            img {
                object-fit: scale-down;
                @include dimensions(20px, auto);
            }
            span {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #FFFFFF;
                margin-left: 15px;
            }
        }
        .chatIcon1 {
            flex-grow: 1;
            @include dimensions(100%, 50%);
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            border-radius: 0 7.5px 7.5px 0 ;
            cursor: pointer;
            // max-width: 79px;
            position: relative;
            svg {
                path {
                    fill: $primary-color1;
                }
            }
            img {
                object-fit: scale-down;
                @include dimensions(20px, auto);
            }
            .messageCount {
                position: absolute;
                left: 50%;
                bottom: 24px;
                cursor: pointer;
                width: 20px;        
                height: 20px;        
                background: $chat-count-background-color;
                border-radius: 20px;
                @include flex(row, nowrap, center, center);
            }
            .messageCount p {
                color: $primary-text-color;
                font-family: Cabin;
                font-style: normal;
                font-weight: bolder;
                font-size: 14px;
            }

        }
    }
}

.boardContainers.chat {
    @include dimensions(auto, 303px);
    .tab {
        .memberIcon1 {
            background: transparent;
            // border-radius: 0px;
            svg {
                path {
                    fill: $primary-color1;
                }
            }
            span {
                color: $primary-color1;
            }
        }
        .chatIcon1 {
            background: $primary-color1;
            color: #ffffff;
            // border-radius: 8px;
            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
    }
}

.waitingRoom_footer {
    @include dimensions(99px, 499px);
    @include absolute(inherit, 0px, 0px, 0px);

    .footer {
        @include dimensions(100%, 100%);
        background-color: #e5e5e5;
        opacity: 0.6;
   }
}

.current_user {
    @include absolute(inherit, inherit, 30px, 25px);
    background: linear-gradient(180deg, rgba(40, 40, 40, 0) 61.82%, #282828 114.09%);
    border: 1.80882px solid $primary-color;
    border-radius: 8px;
    @include dimensions(122px, 218px);
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    span.name {
        @include absolute(inherit, inherit, 8.1px, 12.58px);
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        color: #FFFFFF;
        text-shadow: 2px 2px 4px #000000;
    }
}

.userPin.hide {
    opacity: 0;
}

.userPin {
    @include dimensions(26px, 26px);
    min-width: 26px;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #7B7B7B;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6.5px;
    cursor: pointer;
    svg {
        @include dimensions(100%, 100%);
    }
}
.userPin.active {
    background: $primary-text-color;
    border-color: $primary-text-color;
    svg path {
        fill: $primary-color1;
    }
}

.user_view.noVideo {
    background-color: $secondary-color1;
    .name_calories {
        .name, .calories {
            // text-shadow: 2px 2px 4px #000000;
            color: $secondary-text-color !important;
        }
    }
}
.user_view.me {
    // background: #ffffff;
    .name_calories {
        .name, .calories {
            color: #ffffff;
        }
    }
}
.user_view.me.noVideo {
    background: #ffffff;
    .name_calories {
        .name, .calories {
            text-shadow: 2px 2px 4px #ffffff;
            color: var(--dynamic-variable) !important;
        }
    }
}
.user_view:first-child {
    margin-top: 0px;
}
.user_view:last-child {
    margin-bottom: 0px;
}
.myStreamVIdeo.hide {
    display: none;
    opacity: 0;
}
.myStreamVIdeo.video {
    .name_calories {
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        width: calc(100% - 20px);
        left: 10px;
        right: 10px;
        span {
            color: #FFFFFF !important;
        }
        div {
            color: #FFFFFF !important;
        }
    }
}
.myStreamVIdeo.noVideo {
    // background-color: $box-border-color;
    .member_video1 {
        opacity: 0;
    }
    .name_calories {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0px;
        span {
            text-align: center;
            width: 90%;
            color: $secondary-text-color !important;
            vertical-align: middle;
            .icon_div {
                margin: 0 4px 0;
                svg.scanIcon path {
                    fill: var(--secondary-text-color) !important;
                    stroke: var(--secondary-text-color) !important;
                }
            }
        }
        div {
            color: #FFFFFF !important;
        }
    }
}
.myStreamVIdeo {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    // background: linear-gradient(180deg, rgba(40, 40, 40, 0) 61.82%, #282828 114.09%);
    border-radius: 8px;
    margin: 0px 0px;
    flex-grow: 1;
    overflow: hidden;
    border-width: 1px;
    // border-color: $box-border-color;
    border-style: solid;
    z-index: 4;
    border: 1px solid var(--box-border-color);


}

.user_view {
    position: relative;
    width: calc(100% - 2px);
    padding-top: calc(56.25% + 1px);
    z-index: 6;
    background: linear-gradient(180deg, rgba(40, 40, 40, 0) 67.82%, #282828 114.09%);
    border-radius: 8px;
    margin: 0px 0px;

    .view {
        position:  absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; 
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid $box-border-color;
        .name_calories {
            position: absolute;
            bottom: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 20px);
            left: 10px;
            right: 10px;
            span {
                color: #FFFFFF !important;
            }
            div {
                color: #FFFFFF !important;
            }
        }
        .memberVideo {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            .video_img.show {
                opacity: 1;
                display: inherit;
            }
            img.video_img {
                display: none;
                @include dimensions(60px,60px);
                border-radius: 50%;
                object-fit: cover;
                opacity: 0;
                // margin-bottom: 30px;
            }
            video.video_img {
                display: none;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
            canvas {
                width: 100%;
                height: 100%;
                object-fit: none;
            }
        }
        .userPin.active {
           border-color: $primary-color1; 
        }
        .userPin {
            @include absolute(10px, 10px, auto, auto);
            z-index: 1;
            border: 1px solid #424242;
        }

    }

}

.name {
    font-family: Cabin;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $secondary-text-color;
    max-width: calc(100% - 40px);
    flex-grow: 1;
    display: flex;
    gap: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-shadow: 2px 2px 4px #000000;
}

.calories {
    width: 55px;
    min-width: 55px;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: flex-start;
    // position: absolute;
    // bottom: 10px;
    // right: 10px;

    font-family: Cabin;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $secondary-text-color;
    // text-shadow: 2px 2px 4px #000000;
    .calories_svg {
        height: 20px;
        margin-right: 5.83px;
    }
}

.listButton {
    @include dimensions(40px, calc(50% - 2.5px));
    display: flex;
    justify-content: center;
    align-items: center;
    background: $secondary-color1;
    border-radius: 8px;
    cursor: not-allowed;
    svg {
        @include dimensions(9.21px, 16.79px);
    }
}

.listButton.bottom {
    svg {
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
}
.listButton.active {
    cursor: pointer;
    svg path {
        fill: $primary-color1;
    }
}

// .statsBox_container.showHearteRate {
//     @include dimensions(75px, 410px);
// }

// .statsBox_container.showHeartAndRank {
//     @include dimensions(75px, 674px !important);
// }

// .statsBox_container.showHeartNotRank {
//     @include dimensions(75px, 570px !important);
// }

// .statsBox_container.hideHeartAndRank {
//     @include dimensions(75px, 310px !important);
// }

// .statsBox_container.hideHearteRate {
//     @include dimensions(75px, 500px);
// }


.statsBox_container {
    @include absolute(inherit,0px, 0px, 0px);
    display: flex;
    justify-content: center;
    @include dimensions(76px, max-content);
    min-width: 170px;
    // overflow: hidden;
    transition: height .2s 0s;
    border-radius: 20px 20px 0 0;
    background: var(--background-color);
    border: 2px solid #ffffff;
    border-bottom: 0px;
    backdrop-filter: blur(12px);
    .myRank {
        width: 101px;
        height: 56px;
        border: 1.2px solid $tertiory-color;
        // backdrop-filter: blur(19px);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .rank_icon {
            width: 22px;
            height: 20px;
            margin-right: 10px;
            path:nth-of-type(1) {
                fill: $primary-color1;
            }
            path:nth-of-type(2) {
                fill: $tertiory-color;
            }
        }
        .rank_number {
            font-family: Cabin;
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 32px;
            color: $secondary-text-color;
        }
    }
    .background_stats {
        position: absolute;
        @include dimensions(auto, 100%);
        top: 0;
    }
    .background_stats g path {
        fill: $background-color1;
    }
    .statsBox {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        @include dimensions(auto, 100%);
        padding: 0 20px 0;
        transition: overflow 0s .5s;
        .stats_heart.extraLow {
            path {
                fill: $primary-color1;
            }
        }
        .stats_number.extraLow {
            color: $extraLow;
        }
        .stats_heart.veryLow {
            path {
                fill: $veryLow;
            }
        }
        .stats_number.veryLow {
            color: $veryLow !important;
        }
        .stats_heart.low {
            path {
                fill: $low;
            }
        }
        .stats_number.low {
            color: $low !important;
        }
        .stats_heart.medium {
            path {
                fill: $medium;
            }
        }
        .stats_number.medium {
            color: $medium !important;
        }
        .stats_heart.high {
            path {
                fill: $high;
            }
        }
        .stats_number.high {
            color: $high !important;
        }
        .stats_heart.veryHigh {
            path {
                fill: $veryHigh;
            }
        }
        .stats_number.veryHigh {
            color: $veryHigh !important;
        }

        
        .stats_heartrate {
            @include dimensions(44px, 119px);
            background: $secondary-color1;
            border: 1px solid $box-border-color;
            border-radius: 8px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .stats_heart {
                @include dimensions(22px, 24px);
            }
            .stats_number {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $secondary-text-color;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap:3px;
            }
            .stats_number > span {
                font-weight: 700;
                font-size: 22px;
                line-height: 27px;
                // color: #594AD7;
                margin-right: 1px;
            }
            .stats_number > p {
                color: $secondary-text-color;
            }
        }
        .stats_heartrate_ongoing {
            @include dimensions(56px, 147px);
            background: transparent;
            border: 1.2px solid $box-border-color;
            border-radius: 8px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .stats_heart {
                @include dimensions(22px, 24px);
            }
            .stats_number {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                span {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 27px;
                    color: #594AD7;
                    margin-right: 1px;
                }
            }
        }
        .stats_icon {
            @include dimensions(56px, 56px);
            border-radius: 50%;
            background: $secondary-color1;
            border: 1px solid $primary-color1;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 1;
            .moreIcon {
                path {
                    stroke: var(--secondary-text-color);
                }
            }
            .video {
                width: 29px;
                height: 27px;
            }
            .video {
                width: 29px;
                height: 27px;
            }
            svg path {
                fill: $secondary-text-color;
            }
            svg line {
                stroke: $secondary-text-color;
            }
        }
        .stats_icon.audio {
            @include dimensions(22.25px, 17.8px);
            svg path {
                fill: $primary-color1
            }
        }
        .stats_icon.video {
            @include dimensions(21.67px, 23.21px);
        }
        .stats_icon.active {
            background: $primary-color1;
                svg path {
                    fill: $primary-text-color;
                    // stroke-width: 0.5px;
                    stroke: var(--primary-text-color);
                }
        }
        .stats_icon.disable {
            opacity: 0.6;
            cursor: not-allowed;
        }
        .stats_icon_volume {
            @include dimensions(56px, 56px);
            margin-bottom: 9.5px;
            align-self: flex-end;
            gap: 5px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            background: $secondary-color1;
            border: 1px solid $primary-color1;
            border-radius: 40px;
            min-height: 56px;
            // padding: 16px 0px;
            transition: height .5s 0s;
            overflow: hidden;
            position: relative;
            &:hover {
                height: 196px;
                padding-top: 16px;
                // .range-slider {
                //     // position: relative;
                //     // opacity: 1;
                //     // display: inline-block;
                // }
            }
            &_iconDiv {
                @include dimensions(56px, 56px);
                min-height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            // svg {
            // }
            .volume {
                @include dimensions(28px, 34px);
                min-height: 26px;
                // margin-right: 9px;    
                cursor: pointer;
                path {
                    fill: $secondary-text-color;
                }
            }
            #SliderNative {
                // opacity: 0;
                // transform: rotate(270deg);
                @include dimensions(107px, auto);
            }
        }
    }
}

.statsContainer {
    position: relative; 
    .showStats {
        position: absolute;
        margin: 0;
        z-index: 99999;
        right: 49%;
        @include flex(row, nowrap, space-evenly, center);
        @include dimensions(auto, 45px);
        bottom: 0;
        padding: 10px 0px;
        cursor: pointer;
        color: #FFFFFF;
        background-color: rgba(0, 0, 0, 0.6);
    }
    .fullScreen {
        position: absolute;
        right: 2%;
        outline: none !important;
        bottom: 20%;
        @include flex(row, nowrap, center, center);
        @include dimensions(60px, 60px);
        // background: rgba(0, 0, 0, 0.5);     
        .fullScreenImg {
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: none !important;
            @include dimensions(40px, 50px);            
        }
        .audioIcon {
            @include dimensions(24px, 30px);
            outline: none !important;
            position: absolute;
            cursor: pointer;
            top:20px;
            left: -45px;
        }
    }
    .statsBox {
        padding: 0px 40px;
        @include dimensions(100px, 570px);
        margin:0px auto;
        position: relative;
        .statsImage {
            position: absolute;
            @include dimensions(auto, 490px);
            bottom: 0;
            opacity: 0.6;
        }
        .hideStats {
            z-index: 99999; 
            position: absolute;
            right: 46%;
            top: -20%;
            padding: 10px 0px;
            @include flex(row, nowrap, space-evenly, center);
            @include dimensions(auto, 45px);
            cursor: pointer;
            color: #FFFFFF;
            background-color: rgba(0, 0, 0, 0.6);
        }

   }
}

.pinVideoView {
    position: absolute;
    left: 0;
    top: 130px;
    width: 218px;
    // max-height: 649px;
    padding: 15px;
    background: $background-color1;
    border: 1px solid #FFFFFF;
    border-radius: 0px 16px 16px 0px;
    border-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: width .5s 0s;
    overflow: hidden;
    min-height: 119px;
    z-index: 4;
    max-height: 485px;
    gap: 10px;
}

.memberUi.disable {
    opacity: 0.6;
    .userPin {
        cursor: default;
    }
}

.memberUi.me {
    background-color: #ffffff;
    .memberDetail .name {
        max-width: calc(100% - 70px);
    }
    .memberDetail .rank,.memberDetail .name, .calories {
        text-shadow: 2px 2px 4px #ffffff;
        color: var(--dynamic-variable);
    }
}

.memberUi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    min-height: 50px;
    width: 100%;
    background: $secondary-color1;
    border-radius: 8px;
    margin: 0 0;
    flex-wrap: nowrap;
    .memberDetail {
        display: flex;
        align-items: center;
        // width: calc(100% - 91px);
        flex-grow: 1;
        // white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;
        .rank {
            width: 22.17px;
            min-width: 22.17px;
            color: $secondary-text-color;
            font-family: Cabin;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.02em;
        }

        // .name {
        //     max-width: 90px;
        //     // display: flex;
        //     // span.attempt {
        //     //     // margin-left: 3px;
        //     // }
        // }
        .member_picture {
            height: 41px;
            width: 42.17px;
            min-width: 42.17px;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 5px;
            svg.video_icon {
                position: absolute;
                bottom: 0;
                right: 0;
                path {
                    fill: $primary-color1;
                }
            }
            img {
                width: 32px;
                height: 32px;
                object-fit: cover;
                border-radius: 50%;
            }        
    
        }
    }
    .userPin {
        margin-right: 10px;
    }

}
// .memberUi:first-child {
//     margin-top: 0px;
// }
// .memberUi:last-child {
//     margin-bottom: 0px;
// }

@media screen and (max-width: 1500px) {

    .pinUser.noState {
        .pin-noState {
            @include dimensions(66px, 100%);
            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .stopWatch {
        @include dimensions(auto, 193px);
        @include absolute( 28px, 31px, inherit, inherit);
        .title {        
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }
        .time {
            @include dimensions(auto, 119px);
            .timeUI {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
    
                .number {
                    @include dimensions(47px, 47px);
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 29px;
                }
            }
    
            .timeUI:first-child:after {
                right: -17px;
                font-size: 34px;
                height: 27px;
            }
        }
    }
    .pinVideoView.noState {
        .pin-noState {
            @include dimensions(66px, 98%);
            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    .pinUser {
        width: 218px;
        .userUi {
            @include dimensions(53px, 188px);
            .userDetail {
                font-size: 14px;
                line-height: 18px;
            }
        }
        .userUi:first-child {
            margin-top: 0px;
        }
        .userUi:last-child {
            margin-bottom: 0px;
        }
    } 
    .speaking_div  {
        width: 225px;
    }
    .boardContainers {
        top: 130px;
        bottom: 30px;
        @include dimensions(auto, 225px);
        max-height: 596px;
        .tab {
            // @include dimensions(40px, 100%);
            .memberIcon1 {
                // @include dimensions(100%, 138px);    
                svg {
                    @include dimensions(24px, auto);
                }
            }
            .chatIcon1 {
                // max-width: 69px;
                svg {
                    @include dimensions(24px, auto);
                }
                .messageCount p {
                    font-weight: bolder;
                    font-size: 14px;
                }
            }
        }
        // .member_view_div {
        //     .video-canvas {
        //         height: 478px;
        //     }
        // }
    }  
    .listButton {
        @include dimensions(35px, calc(50% - 2.5px));
        border-radius: 6px;
    }  
    .user_view {    
        .view {
            .memberVideo {
                img {
                    @include dimensions(60px,60px);
                    object-fit: cover;
                }
            }
            .name_calories {
                .name {
                    font-size: 16px;
                }
            }
        }
    
    }
    .userPin {
        @include dimensions(24px, 24px);
        min-width: 24px;
        padding: 5.5px;
    }
    .pinVideoView {
        width: 218px;
        // max-height: 363px;
    }
}
.video-canvas_ongoing {
    display: flex;
    height: auto;
    left: 15px;
    position: absolute;
    // top: 0;
    z-index: 3;
}

.pin_container_zoom {
    width: 218px;
    position:absolute;
    left: 0;
    top: 130px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    min-height: 119px;
    background: var(--background-color);
    border: 1px solid #FFFFFF;
    border-radius: 0px 16px 16px 0px;
    z-index: 4;
    gap: 10px;
    max-height: 485px;
    border-left: 0px;
}
.pin_container_zoom.noState {
    // padding-right: 0px;
    .pin-noState {
        // padding-left: 10px;
        @include dimensions(57px, 100%);
    	display: flex;
	    flex-direction: row;
        gap: 10px;
    	justify-content: space-between;
        align-items: center;
        .userPin {
            cursor: default;
        }
        p {
            margin: 0px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $secondary-text-color;
            letter-spacing: 0.5px;
        }
    }
}

.speaking_div {
    position: absolute;
    top: 88px;
    right: 5px;
    // right: 0;
    @include flex(row, nowrap, center, unset);
    // height: 44px;
    width: 248px;
    overflow: hidden;
    // .speaking_background {
    //     height: 8px;
    //     z-index: 1;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     background-color: $primary-color1;
    // }
    // .speaking_left {
    //     height: 35px;
    //     border-radius: 0px 30px 0px 0px;
    //     flex-grow: 1;
    //     margin-right: -9px;
    //     border-top: 8px solid $primary-color1;
    //     border-right: 9px solid $primary-color1;
    // }  
    .speaking {
        background-color: $primary-color1;
        // min-width: 342px;
        width: fit-content;
        border-radius: 10px;
        // border-top: 8px solid $primary-color1;
        // font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $primary-text-color;
        text-align: center;
        z-index: 1;
        padding: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    
    }  
    // .speaking_right {
    //     border-radius: 30px 0px 0px 0px;
    //     flex-grow: 1;
    //     margin-left: -9px;
    //     border-left: 9px solid $primary-color1;
    //     border-top: 8px solid $primary-color1;
    //     height: 35px;
    // }  
}
// .zoom_pinned_video_container {
//     font-family: cabin;
//     box-sizing: border-box;
//     position: absolute;
//     left: 0;
//     top: 130px;
//     right: 0px;
//     width: 248px;
//     height: auto;
//     background: var(--background-color);
//     border: 1px solid #FFFFFF;
//     border-radius: 0px 16px 16px 0px;
//     border-left: 0px;
//     display: flex;
//     flex-direction: column;
//     flex-wrap: nowrap;
//     justify-content: space-between;
//     align-items: normal;
//     transition: width .5s 0s;
//     gap: 12px;
//     flex: 1;
//     overflow: hidden;
//     .pinned_member_view_div {
//         display: flex;
//         flex-direction: column;
//         flex-grow: 1;
//         justify-content: space-between;
//         position: relative;
//         overflow: hidden;
//         padding: 0px 14px 13px;
//         flex: 1;
//         gap: 5px;
//         .pinned_video-canvas {
//             z-index: 3;
//             display: flex;
//             position: fixed;
//         }
//         .pinned_user_container {
//             flex-direction: column;
//             overflow-y: scroll;
//             flex-grow: 1;
//             z-index: 4;
//             display: flex;
//             width: 100%;
//             flex: 1;
//             height: 100%;
//             margin-top: 10px;
//             gap: 10px;
//             overflow: hidden;           
//         }
//     }
// }≈