@import '../../../Sass/Mixins.scss';
@import '../../../Sass/Variables.scss';

.chatListContainer {
    width: 100%;
    // padding-top: 10px;
    border-radius: 8px;
    // background: #424242;
    min-height: 200px;
    height: 100%;
    max-height: 800px;
    overflow-y: scroll;
    @include flex(column, nowrap, space-between, center);
    padding: 0px 14px 13px;

    .chatList {
        width: 100%;
        overflow-y: scroll;
        @include flex(column-reverse, nowrap, flex-start, unset);
        flex-grow: 1;
        margin-bottom: 5px;
        position: relative;

        .noMessage {
            position: absolute;
            color: #ffffff8c;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            justify-content: center;
            font-weight: 500;
            &.light {
                color: #505050;
            }
            &.dark {
                color: #FFFFFF;
            }
        }

        .messageBox.sender {
            flex-direction: row;

            .messageBox_user_profile {
                margin-left: 5px;
            }

            .messageBox_div {

                .messageTo {
                    flex-direction: row-reverse;
                }
                .messageBox_outer {
                    border-radius: 12px 12px 0px 12px;
                    border-right: 5px solid transparent;
                    border-bottom-color: $sender-background;
                    border-left: 2px solid $sender-background;

                    &.personal {
                        border-bottom-color: $personal-background;
                        border-left-color: $personal-background;

                        .message {
                            background-color: $personal-background;

                            p,
                            .senderName {
                                color: $personal-background-text;
                            }
                        }
                    }

                    .message {
                        background-color: $sender-background;

                        p {
                            color: $sender-background-text;
                        }

                        .senderName {
                            color: $sender-background-text;
                            display: none;
                        }
                    }
                }
            }
        }

        .messageBox.reciver {
            flex-direction: row-reverse;

            .messageBox_user_profile {
                margin-right: 5px;
            }

            .messageBox_div {
                // .messageTo {
                //     margin-left: 10px;
                //     width: calc(100% - 10px);
                // }

                .messageBox_outer {
                    border-radius: 12px 12px 12px 0px;
                    border-left: 5px solid transparent;
                    border-bottom-color: $receiver-background;
                    border-right: 2px solid $receiver-background;

                    &.personal {
                        border-bottom-color: $personal-background;
                        border-right-color: $personal-background;

                        .message {
                            background-color: $personal-background;

                            p,
                            .senderName {
                                color: $personal-background-text;
                            }
                        }
                    }

                    .message {
                        background-color: $receiver-background;
                        padding: 10px 10px 0px;

                        p,
                        .senderName {
                            color: $receiver-background-text;
                        }

                    }
                }
            }
        }

        .messageBox {
            width: 100%;
            display: flex;
            margin: 4px 0px;
            padding: 10px 0px;
            justify-content: flex-end;
            align-items: flex-end;

            .messageBox_div {
                display: flex;
                flex-direction: column;
                width: calc(100% - 100px);
                max-width: 300px;

                .messageTo {
                    font-family: Cabin;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                    width: 100%;
                    margin: 0px;
                    padding: 0px 5px;

                    span.time {
                        font-size: 10px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column; 
                        text-wrap: nowrap;                   
                    }

                    &.dark {
                        color: #FFFFFF;
                    }


                }

                .messageBox_outer {
                    border-bottom: 5px solid transparent;
                    min-width: 90px;
                    border-radius: 8px;
                    // overflow: hidden;
                    // max-width: calc(100% - 70px);
                    flex-grow: 1;
                    display: flex;

                    .message {
                        padding: 10px 10px 5px;
                        position: relative;
                        border-radius: 10px 10px 0px 0px;
                        width: 100%;

                        .senderName {
                            margin: 0;
                            // color: $secondary-text-color;
                            text-align: right;
                            font-size: 11px;
                            font-family: Cabin;
                            font-style: normal;
                            font-weight: normal;
                            width: 100%;
                            position: initial;
                            float: right;
                            line-height: 20px;
                            margin-left: 10px;
                        }

                        // .senderName1 {
                        //     margin: 0;
                        //     // color: $primary-text-color;
                        //     text-align: right;
                        //     font-size: 11px;
                        //     font-family: Cabin;
                        //     font-style: normal;
                        //     font-weight: normal;
                        //     width: 80%;
                        //     position: initial;
                        //     float: right;
                        //     line-height: 20px;
                        // }
                        p {
                            margin: 0;
                            font-size: 15px;
                            font-family: Cabin;
                            font-style: normal;
                            font-weight: normal;
                            word-break: break-word;
                            white-space: pre-wrap;
                        }
                    }
                }
            }

        }

        .messageBox img {
            background-image: url("../../../assets/Images/dummy.jpg");
            background-size: cover;
            height: 30px;
            width: 30px;
            border-radius: 20px;
            object-fit: cover;
        }
    }

    .chatBox {
        width: 100%;
        position: relative;
        @include flex(row, nowrap, space-between, center);

        .sendButton {
            background: $primary-color1;
            border-radius: 7px;
            padding: 10px;
            height: 45px;
            width: 45px;
            cursor: pointer;
            @include flex(row, nowrap, center, center);

            &.active {
                background-color: $secondary-color1;
            }

            .activeSend {
                path {
                    fill: $primary-text-color;
                }
            }

            .inActiveSend {
                path {
                    fill: $secondary-text-color;
                }
            }
        }

        .sendButton svg {
            height: 18px;
        }

        .selectedUser {
            height: 30px;
            right: 60px;
            position: absolute;
            width: 30px;
            cursor: pointer;
            border-radius: 7px;
            background: $layer-one;
            @include flex(row, nowrap, center, center);
        }

        .selectedUser img {
            height: 24px;
            width: 24px;
            object-fit: cover;
        }

        .selectedUser svg {
            width: 24px;

            path {
                fill: $secondary-text-color;
            }
        }

        .chatDropDown {
            position: absolute;
            width: 125px;
            right: 0;
            // top: -140px;
            bottom: 50px;
            padding: 0px 0px 0px 10px;
            background: #282828;
            box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
            border-radius: 8px;

            .selUserType {
                cursor: pointer;
                border-image: linear-gradient(80deg, #28282828, #FFFFFF, #28282828) 10;
                @include flex(row, nowrap, flex-start, center);
                position: relative;
                &.borderB {
                    border-bottom: "1px solid #FFFFFF";
                }
            }

            .selUserType img {
                margin-right: 10px;
                height: 25px;
                width: 25px;
                object-fit: scale-down;
            }

            .selUserType p {
                color: #FFFFFF;
                font-family: Cabin;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 80px;
            }
        }
    }

    .chatBox input {
        width: calc(100% - 55px);
        height: 45px;
        outline: none !important;
        padding: 10px 45px 10px 10px;
        border-radius: 7px;
        border: 1px solid #FFFFFF;
        background: #FFFFFF;
        font-size: 14px;
        font-family: Cabin;
        font-style: normal;
        font-weight: 300;
    }

    .chatBox input::placeholder {
        font-family: Cabin;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #C1C1C1;
    }

    .chatBox input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: Cabin;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #C1C1C1;
    }

    .chatBox input::-ms-input-placeholder {
        /* Microsoft Edge */
        font-family: Cabin;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #C1C1C1;
    }
}

@media screen and (max-width: 1500px) {
    .chatListContainer {
        .chatList {
            .messageBox {
                .message {
                    min-width: 90px;

                    .senderName {
                        font-size: 10px;
                        font-weight: 300;
                    }
                }

                .message p {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .chatBox {
            .sendButton {
                height: 40px;
                width: 40px;
            }

            .selectedUser img {
                height: 22px;
                width: 22px;
            }

            .chatDropDown {
                padding: 0px 0px 0px 5px;

                .selUserType img {
                    margin-right: 6px;
                    margin-left: 5px;
                    height: 22px;
                }
            }
        }

        .chatBox input {
            height: 40px;
        }
    }

}