@import '../../../Sass/Variables.scss';
@import '../../../Sass/Mixins.scss';

.member_video1 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    // padding-top: 56.25%;
    // background: linear-gradient(180deg, rgba(40, 40, 40, 0) 61.82%, #282828 114.09%);
    // border-radius: 8px;
    // margin: 5px 0px;
}
.member_video {
    position: absolute;
    width: 218px;
    height: 122px;
    bottom: 25px;
    right: 86px;
    display: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
    border-radius: 6px;
}

.member_video.show {
    display: block;
    z-index: 12;
}

.startMyVideo {
    position: absolute;
    bottom: 30px;
    right: 86px;
    width: 152px;
    height: 36px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $primary-text-color;
    background: $primary-color1;
    border-radius: 8px;
    cursor: pointer;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stopMyVideo {
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 37px;
    right: 98px;
    background: $primary-color1;
    border-radius: 4px;
    z-index: 12;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 22px;
        path {
            fill: $primary-text-color;
            stroke-width: 0.5px;
            stroke: $primary-text-color;
        }
    }
}